import { Carousel } from '@hexa-ui/components';
import BTGuideSection from 'Components/BTGuideSection/BTGuideSection';
import CardReports from 'Components/CardReports/CardReports';
import { CardProps } from 'Components/CardReports/CardReports.types';
import CardSection from 'Components/CardSection/CardSection';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { WorkspaceCard } from 'Components/WorkspaceCard/WorkspaceCard';
import React from 'react';
import { useIntl } from 'react-intl';
import { Styled } from './MainPage.style';

const mockWorkspaces = [
  {
    id: '001',
    title: 'Local Bees',
    segments: 0,
    isSelected: false,
  },
  {
    id: '002',
    title: 'Global',
    segments: 1,
    isSelected: true,
  },
  {
    id: '003',
    title: 'Big name to test behavior',
    segments: 2,
    isSelected: false,
  },
  {
    id: '004',
    title: 'Another name here',
    segments: 3,
    isSelected: false,
  },
  {
    id: '005',
    title: 'Nice name',
    segments: 4,
    isSelected: false,
  },
  {
    id: '006',
    title: 'Fantastic name',
    segments: 5,
    isSelected: false,
  },
  {
    id: '007',
    title: 'Bad name',
    segments: 6,
    isSelected: false,
  },
  {
    id: '008',
    title: 'Nameless',
    segments: 7,
    isSelected: false,
  },
  {
    id: '009',
    title: 'More one',
    segments: 7,
    isSelected: false,
  },
];

const cardMock: CardProps[] = [
  {
    key: '001',
    title: 'Common Customer Core KPIs: Active users',
    icon: 'Accessibility',
    workspace: 'Workspace 1',
  },
  {
    key: '002',
    title: 'Common CustomerCore KPIs: Active users',
    icon: 'AppGrid',
    workspace: 'Workspace 2',
  },
  {
    key: '003',
    title: 'Common CustomerCore KPIs: Active users',
    icon: 'ArrowRight',
    workspace: 'Workspace 3',
  },
  {
    key: '004',
    title: 'Common CustomerCore KPIs: Active users',
    icon: 'Accessibility',
    workspace: 'Workspace 1',
  },
  {
    key: '005',
    title: 'Common CustomerCore KPIs: Active users',
    icon: 'AppGrid',
    workspace: 'Workspace 2',
  },
  {
    key: '006',
    title: 'Common CustomerCore KPIs: Active users',
    icon: 'ArrowRight',
    workspace: 'Workspace 3',
  },
];

const WORKSPACES_TO_SHOW = 8;

export function MainPage(): React.ReactElement {
  const { formatMessage } = useIntl();

  const hasBTGuidePermission = true;

  const filteredWorkspaces = mockWorkspaces.slice(0, WORKSPACES_TO_SHOW);

  return (
    <PageContainer maxWidth="xl">
      <Sidebar />
      <HeaderContainer pageTitle={formatMessage({ id: 'MAIN_PAGE.TITLE' })} />
      <Styled.Container>
        <Styled.Row>
          <Styled.MainSection hasBTGuidePermission={hasBTGuidePermission}>
            <CardSection
              title={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.TITLE' })}
              subtitle={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.SUBTITLE' })}
              hasRedirect={cardMock.length > 4}
              redirectText={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.BUTTON' })}
              pagePath="main-page/all-reports"
            >
              {cardMock?.length > 0 && (
                <Carousel.Root
                  showArrows
                  size={hasBTGuidePermission ? 'md' : /* istanbul ignore next */ 'sm'}
                  swipeable
                >
                  {cardMock?.map((item) => (
                    <CardReports
                      key={item.key}
                      title={item.title}
                      icon={item.icon}
                      workspace={item.workspace}
                    />
                  ))}
                </Carousel.Root>
              )}
            </CardSection>
          </Styled.MainSection>
          {hasBTGuidePermission && <BTGuideSection />}
        </Styled.Row>
      </Styled.Container>

      <Styled.Container>
        {filteredWorkspaces?.length > 0 && (
          <CardSection
            title={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.TITLE' })}
            subtitle={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.DESCRIPTION' })}
            hasRedirect={mockWorkspaces.length > WORKSPACES_TO_SHOW}
            redirectText={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.BUTTON' })}
            pagePath="main-page/all-workspaces"
            paddingBottom={false}
          >
            <Carousel.Root showArrows size="sm" swipeable={false}>
              {filteredWorkspaces.map((workspace) => (
                <WorkspaceCard
                  key={workspace.id}
                  title={workspace.title}
                  segments={workspace.segments}
                  isSelected={workspace.isSelected}
                />
              ))}
            </Carousel.Root>
          </CardSection>
        )}
      </Styled.Container>
    </PageContainer>
  );
}
