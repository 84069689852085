import { Card, Heading } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import Challenge from '../../assets/images/challenge.svg';
import { Styled } from './BTGuideSection.style';

function BTGuideSection() {
  const { formatMessage } = useIntl();

  return (
    <Card border="medium" elevated="small" css={{ width: '422px', padding: '24px', gap: '8px' }}>
      <Heading size="H3">{formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.TITLE' })}</Heading>
      <Styled.LinkContainer href={'/'}>
        {formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.LINK' })}
      </Styled.LinkContainer>
      <Card
        border="medium"
        elevated="small"
        css={{
          padding: '24px',
          gap: '8px',
          boxShadow: 'none',
          backgroundColor: '#FAFAFA',
          border: 'solid 1px rgba(20, 20, 20, 0.12)',
        }}
      >
        <Styled.ImageAndTextWrapper>
          <img src={Challenge} alt="challenge" />
          <Heading size="H4">
            {formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.DESCRIPTION' })}
          </Heading>
        </Styled.ImageAndTextWrapper>
      </Card>
    </Card>
  );
}

export default BTGuideSection;
