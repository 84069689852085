import MessageMap from 'I18n/i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },

  MAIN_PAGE: {
    TITLE: 'Data & Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'Business transformation guide',
      DESCRIPTION: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    REPORT_SECTION: {
      TITLE: 'Reports',
      SUBTITLE: 'Explore detailed reports on different themes.',
      BUTTON: 'Show all reports',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Workspaces',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all workspaces',
    },
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'segment',
    PLURAL_TEXT: 'segments',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
    MAIN_PAGE: 'Data & Analytics',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data and analytics',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Global overview',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Something went wrong',
    CONTAINER_ERROR_LABEL: "Sorry, we're having technical issues. Please try again.",
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Export CSV',
    DESCRIPTION: 'Select the period for the download.',
    YEAR_LABEL: 'Year',
    MONTH_LABEL: 'Month',
    NO_INFO_AVAILABLE:
      "There's no information for the selected period. Please try a different date.",
    CANCEL: 'Cancel',
    DOWNLOAD: 'Download',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Business insights taken to the next level',
    FIRST_DESCRIPTION: 'Get access to CSV export and many other features to improve your analysis.',
    BUTTON_BECOME: 'Become a Gold Partner',
    BUTTON_LATER: 'Later',
    MODAL_STYLE: 'EN',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Export data',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Your free trial will expire soon',
    FIRST_DESCRIPTION:
      'This CSV you are about to download is a courtesy from our membership program.',
    SECOND_DESCRIPTION:
      'To keep access to special insights tailored for your company in the future, you’ll have to upgrade to Gold Partner.',
    BUTTON: 'DOWNLOAD FILE',
    NO_INFO_AVAILABLE: "There's no information for the period.",
  },

  HELP_CENTER_MODAL: {
    TITLE: 'How to access a CSV file',
    DESCRIPTION:
      'Not familiar with CSV files? Check a step-by-step guide on how to use a CSV file.',
    LINK: 'Show guide to CSV files',
    PROCEED_BUTTON: 'Proceed to download',
  },

  GET_STARTED: {
    LOADING: 'Loading',
    TITLE: 'Get started',
    PARAGRAPH: 'Select a vendor to see their data and insights.',
    NEXT: 'Next',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Change',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },

  COMMON: {
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Loading',
    PARAGRAPH: 'We are preparing the page for you.',
  },
  SELECT_VENDOR: {
    TITLE: 'Select a vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Access denied!',
    ERROR_DESCRIPTION: 'It looks like you don’t have permission to access this page.',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Preparing CSV file for download.',
    SUCCESS_DOWNLOAD: 'Download completed successfully.',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Become Premium',
    TITLE_NORMAL: `We've got a plan\nthat's perfect for`,
    TITLE_HIGHLIGHTED: 'your business',
    SUBTITLE: 'Power up your analysis with the right membership for you company.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'For businesses starting in the digital marketplace world.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Current plan',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'For businesses with advanced data intelligence teams that want to invest in targeted audiences.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Contact us',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Metrics',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Units Delivered',
        ORDERS_DELIVERED: 'Orders Delivered',
        BUYERS: 'Buyers',
        UNIT_DELIVERED_BY_PRODUCT: 'Unit Delivered by Product',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Units Delivered by City and State',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Units Sold by Distribution Center',
        TOP_5_PRODUCTS: 'Top 5 Products',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Monthly evolution of Orders',
        REVENUE: 'Revenue',
        AVERAGE_REVENUE_PER_ORDER: 'Average Revenue per Order',
        AVERAGE_REVENUE_PER_BUYER: 'Average Revenue per Buyer',
        REVENUE_BY_REGION: 'Revenue by Region',
        MONTHLY_SALES_REVENUE: 'Monthly Sales Revenue',
        CURRENT_MONTH_VS_LAST_MONTH: 'Current Month vs Last Month',
        ORDERS_VS_REVENUE_STATE: 'Orders vs Revenue/State',
        ORDERS_VS_REVENUE_SEGMENT: 'Orders vs Revenue/Segment',
        ORDERS_VS_REVENUE_PRODUCT: 'Orders vs Revenue/Product',
        REVENUE_BY_PRODUCT: 'Revenue by Product',
        REVENUE_BY_SEGMENT: 'Revenue by Segment',
        REVENUE_BY_CHANNEL: 'Revenue by Channel',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Date',
        GROSS_VALUE: 'Gross Value',
        REGION_STATE_AND_CITY: 'Region (State and City)',
        DISTRIBUTION_CENTER: 'Distribution Center',
        SEGMENT: 'Segment',
        SUB_SEGMENT: 'Sub-Segment',
        CHANNEL: 'Channel',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Business insights taken to the',
    TITLE_NEXT_LEVEL: 'next level',
    DESCRIPTION:
      'With our premium insights you can discover powerful analysis that will unleash all the potential of your business.',
    BUTTON: 'Become a Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusive for Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brazil',
    CL: 'Chile',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ID: 'Indonesia',
    MX: 'Mexico',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Philippines',
    PY: 'Paraguay',
    ZA: 'South Africa',
    CH: 'Switzerland',
    GLOBAL_OVERVIEW: 'Global overview',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Select a country',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Select a business model',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Select a vendor',
    LOADING: 'Loading...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Fetching the data...',
      BODY: 'Step 1 out of 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Loading report...',
      BODY: 'Step 2 out of 3',
    },

    LOADING_DATA: {
      TITLE: 'We are almost there',
      BODY: 'Step 3 out of 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    FEATURE_SETUP: 'Feature Setup',
    FEATURE_ACTIVATION: 'Feature Activation',
    PARTNER_REGISTRATION: 'Partner Registration',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Additional reports & documentation',
    DESCRIPTION: 'Access additional reports from other BEES products.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Understanding our KPIs',
    SUBTITLE:
      'This page explains what our KPIs (key performance indicators) are and how they are calculated. To find a specific KPI, use the search bar or navigate through the tabs below.',
    COLUMNS: {
      NAME: 'KPI name',
      DESCRIPTION: 'Description',
      METHODOLOGY: 'Calculation methodology',
    },
    EMPTY_STATE: 'Oops, sorry! No results found.',
    SEARCH_FIELD: 'Search by KPI or keyword',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'No results found.',
      EMPTY_STATE_TEXT: 'Check your spelling or try using different search terms.',
      COUNT_RESULT_MESSAGE: ' results for ',
      UNIT_RESULT_MESSAGE: '1 result for ',
    },
  },

  REPORT_ADMIN_PAGE: {
    DATA_ANALYTICS_TITLE: 'Data & Analytics Setup Tool',
    THEME_TITLE: 'Theme',
    THEME_SUBTITLE: 'These themes will be used to create the UX menu navigation options',
    FEATURE_SETUP: 'Feature Setup',
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    NO_DATA: 'Sorry, no results found.',

    REPORT_DETAILS: {
      HEADER: {
        TITLE: 'Title',
        TRACKING_ID: 'Tracking ID',
        TAB_ORDER: 'Tab order',
        WORKSPACE_ID: 'Workspace ID',
        REPORT_ID: 'Report ID',
      },
      RULES: {
        TITLE: 'Rules',
        ADD_NEW: 'Add new rule',
        USER_TYPE: 'User type',
        BUSINESS_MODEL: 'Business model',
        ACTIONS: 'Actions',
        VENDOR: 'Feature Activation',
        ACTIVE: 'Active',
      },
      TABLE: {
        MANUFACTURER: 'Manufacturer',
        SELLER: 'Seller',
      },
    },
  },

  REPORT_VENDOR_ADMIN_PAGE: {
    VENDOR_MANAGEMENT: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
    VENDOR_MANAGEMENT_DETAILS: {
      HEADER: {
        TITLE: 'Feature Activation Management',
        SUBTITLE: 'Storefront partners (DTaaS and SaaS)',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        DTAAS_REPORTS: 'DTaaS Reports',
        SALES_MANAGER_REPORTS: 'Sales Manager Reports',
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
  },

  REPORT_DETAILS_PAGE: {
    THEME_NAME: 'Theme name',
    STRUCTURE: 'Structure',
    TRACKING_ID: 'Tracking ID',
    ORDER: 'Order',
    REPORTS: 'Reports',
    REPORT: 'Report',
    WORKSPACE_ID: 'Workspace ID',
    REPORT_ID: 'Report ID',
    RLS_RULE: 'RLS rule',
    ADD_NEW: 'Add new',
  },

  REPORT_NEW_RULE_MODAL: {
    ADD_NEW_RULE: 'Add new rule',
    USER_TYPE: 'User type',
    SELECT_USER_TYPE: 'Select user type',
    BUSINESS_MODEL: 'Business model',
    BUSINESS_PLACEHOLDER: 'Type business model',
    CANCEL: 'Cancel',
    ADD: 'Add',
  },

  DELETE_RULE_MODAL: {
    TITLE: 'Delete rule?',
    DESCRIPTION: 'Are you sure that you want delete the rule?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
  },

  ACTIVATION_PAGE: {
    CLUSTER_SELECT: {
      LABEL: 'Select a cluster',
      GLOBAL_US: 'Global US',
      GLOBAL_EU: 'Global EU',
      SELECTED_CLUSTER: 'Selected cluster',
    },
    THEME_ADD: 'Add new theme',
    REPORT_ADD: 'Add new report',
    ADD_NEW_THEME_MODAL: {
      MODAL_TITLE: 'Add new theme',
      MODAL_EDIT_TITLE: 'Edit theme',
      NAME: 'Name',
      NAME_US: 'Enter a US name',
      NAME_PT: 'Enter a PT name',
      NAME_ES: 'Enter a ES name',
      NAME_ID: 'Enter a ID name',
      NAME_HINT_US: 'en-US',
      NAME_HINT_PT: 'pt-BR',
      NAME_HINT_ES: 'es-419',
      NAME_HINT_ID: 'id-ID',
      ICON: 'Icon',
      ICONS_PLACEHOLDER: 'Select an icon',
      PREVIEW_ICON: 'Preview',
      STRUCTURE: 'Structure',
      STRUCTURE_TAB: 'Tab',
      STRUCTURE_TOGGLE: 'Toggle',
      SLUG: 'Slug',
      SLUG_PLACEHOLDER: 'Enter a slug',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      ORDER: 'Order',
      ORDER_PLACEHOLDER: 'Enter an order',
      CANCEL: 'Cancel',
      CONFIRM: 'Add theme',
      EDIT: 'Edit theme',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
    },
    ADD_NEW_REPORT_MODAL: {
      MODAL_TITLE: 'Add new report',
      MODAL_EDIT_TITLE: 'Edit report informations',
      TITLE: 'Title',
      TITLE_US: 'Enter a US title',
      TITLE_PT: 'Enter a PT title',
      TITLE_ES: 'Enter a ES title',
      TITLE_ID: 'Enter a ID title',
      TITLE_HINT_US: 'en-US',
      TITLE_HINT_PT: 'pt-BR',
      TITLE_HINT_ES: 'es-419',
      TITLE_HINT_ID: 'id-ID',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      WORKSPACE_ID: 'Workspace ID',
      WORKSPACE_PLACEHOLDER: 'Enter a Workspace ID',
      REPORT_ID: 'Report ID',
      REPORT_ID_PLACEHOLDER: 'Enter a Report ID',
      TAB_ORDER: 'Tab order',
      TAB_ORDER_PLACEHOLDER: 'Enter an Tab order',
      USERTYPE: 'User type',
      USERTYPE_PLACEHOLDER: 'Select user type',
      BUSINESS_MODEL: 'Business model',
      BUSINESS_MODEL_PLACEHOLDER: 'Enter a Business model',
      CANCEL: 'Cancel',
      CONFIRM: 'Add',
      EDIT: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      IS_MANUFACTURER: 'Manufacturer',
    },
  },
  VENDOR_MANAGEMENT_DETAILS_PAGE: {
    PARTNER_AND_MANAGER_TABLE_TITLE: 'DTaaS Reports',
    SALES_MANAGER_TABLE_TITLE: 'Sales Manager Reports',
    TABLE_PARTNER_AND_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
    TABLE_SALES_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
  },
  VENDOR_REGISTRATION_PAGE: {
    TITLE: 'Vendor access',
    SUBTITLE: 'Vendors',
    ADD_BUTTON: 'Add new',
    MODAL: {
      TITLE_ADD_VENDOR: 'Add new vendor',
      TITLE_EDIT_VENDOR: 'Edit vendor',
      NAME_LABEL: 'Name',
      NAME_PLACEHOLDER: 'Enter a name',
      VENDOR_ID_LABEL: 'Vendor Id',
      VENDOR_ID_PLACEHOLDER: 'Enter a vendor Id',
      MANUFACTURER_LABEL: 'Manufacturer',
      CANCEL_BUTTON: 'Cancel',
      ADD_BUTTON: 'Add',
      EDIT_BUTTON: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      TITLE_DELETE_VENDOR: 'Delete Vendor',
      CONFIRMATION_TEXT: 'Are you sure that you want delete the vendor?',
      CONFIRM_BUTTON: 'Confirm',
    },
  },
  THEMES_LIST: {
    TITLE: 'Themes List',
  },
  VENDORS_LIST: {
    TITLE: 'Vendors List',
  },
  VENDORS_UPDATE: {
    SUCCESS: 'Vendor updated successfully.',
    FAIL: 'Vendor update failed',
  },
  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },
  WORKSPACES: {
    TITLE: 'Workspaces',
    ADD_NEW: 'Add new',
    PAGE_TITLE: 'Data & Analytics Admin',
    CARD: {
      ACCESS_RULES: 'Access rules',
      USER: 'User',
      USERS: 'Users',
    },
  },
};

export default enUS;
