import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$6',
});

const Row = styled('div', {
  display: 'flex',
  gap: '$6',
});

const MainSection = styled('div', {
  flexGrow: 1,
  variants: {
    hasBTGuidePermission: {
      true: {
        width: '70%',
      },
      false: {
        width: '100%',
      },
    },
  },
});

export const Styled = {
  Container,
  Row,
  MainSection,
};
