import { Heading, Paragraph } from '@hexa-ui/components';
import { IconName } from 'Components/IconsMap/IconsMap';
import iconMap from 'Utils/iconMap/iconMap';
import React, { useCallback } from 'react';
import Styled from './CardReports.style';
import { CardProps } from './CardReports.types';

export const GRAY_COLOR = 'rgba(20, 20, 20, 0.56)';

function CardReports({ title, icon, workspace }: CardProps): React.ReactElement {
  const renderIcon = useCallback(
    (iconName: IconName) => {
      const IconComponent = iconMap[iconName];
      return IconComponent;
    },
    [iconMap]
  );

  return (
    <Styled.CardContainer
      border="medium"
      data-testid="report-card"
      id="report-card"
      elevated="minimal"
    >
      <Styled.Circle>{renderIcon(icon)}</Styled.Circle>
      <Heading size="H4">{title}</Heading>

      <Styled.CardContent>
        <Paragraph size="xsmall" css={{ color: GRAY_COLOR }}>
          {workspace}
        </Paragraph>
      </Styled.CardContent>
    </Styled.CardContainer>
  );
}

export default CardReports;
